import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import { TbCircleX } from 'react-icons/tb'

gsap.registerPlugin(ScrollTrigger)
ReactModal.setAppElement('#___gatsby')
export default function TravelClinicModal() {
  const [modalOpen, setModalOpen] = useState(false)
    useEffect(() => {
      if(!typeof window !== 'undefined' && !window.sessionStorage.getItem('modalViewed')){
        setTimeout(() => {
          setModalOpen(true)
        }, 500)
      }
    }, [])
    
    function handleModalClose() {
      setModalOpen(false)
      sessionStorage.setItem('modalViewed', true)
    }
  
  return (
    <>
    <ReactModal
    isOpen={modalOpen}
    role={"dialog"}
    portalClassName="popUpModal"
    onRequestClose={handleModalClose}
    preventScroll={true}
    shouldFocusAfterRender={true}
    aria={{
      labelledby: 'modalTitle',
      describedby: 'modalText'
    }}
    style = {
     { overlay: {
        backfaceVisibility: 'hidden',
        position:'fixed',
        zIndex:'800',
        overflow:"hidden !important",
        background:`rgb(0, 0, 0, 0.64)`
      },
      content: {
        borderRadius:'20px',
        inset: '40px',
        maxWidth:'50%',
        border:'solid 4px var(--black)',
        overflow:'scroll',
        margin:'auto',
        height:'fit-content',
        maxHeight:'80vh',
        padding:'50px 25px'
      }
    }
    }
    contentLabel={`Kids n Family Travel Clinic CTA`}>
      <button className="modalClose" aria-label="close" title="Close" onClick={handleModalClose}><TbCircleX /></button>
      <h2>Visit our New Travel Clinic!</h2>
      <p>We are pleased to announce our new <span className="strong">Kids 'n Family Travel Clinic</span></p>
      <p>Visit the new <a class="hoverable" href="https://kidsnfamilytravelclinic.ca/" target="_blank" rel="noopener noreferrer" onClick={handleModalClose}>Website</a> to learn more!</p>
    </ReactModal>
    </>
  )
}